import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SRLWrapper } from "simple-react-lightbox"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DicaExtra from "../components/DicaExtra"

import * as S from "../components/Pages/general"

const GalleryPage = () => {
  const {
    galeria1,
    galeria2,
    galeria3,
    galeria4,
    galeria5,
    galeria6,
    galeria7,
    galeria8,
    galeria9,
    galeria10,
    galeria11,
    galeria12,
    galeria13,
    galeria14,
    galeria15,
  } = useStaticQuery(
    graphql`
      query {
        galeria1: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria1.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria2: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria2.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria3: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria4: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria5: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria5.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria6: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria6.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria7: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria7.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria8: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria8.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria9: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria9.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria10: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria10.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria11: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria11.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria12: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria12.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria13: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria13.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria14: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria14.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeria15: file(
          relativePath: { eq: "galeria/metodo-fan-art-galeria15.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const options = {
    buttons: {
      backgroundColor: "rgba(30,30,36,0.8)",
      iconColor: "rgba(255, 255, 255, 0.8)",
      iconPadding: "5px",
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: "40px",
    },
  }

  return (
    <Layout>
      <SEO
        title="Galeria"
        description="Veja exemplos de como aplicar seu conhecimento adquirido no Método Fan Art, ou ainda, veja os resultados obtidos através do método."
      />
      <S.GeneralTitle>Galeria de Desenhos</S.GeneralTitle>
      <S.GeneralText>
        Aqui temos algum exemplo para inspirar vocês nessa jornada.
      </S.GeneralText>
      <SRLWrapper options={options}>
        <S.GeneralGaleria>
          <S.GeneralImagePequena
            shadow
            fluid={galeria1.childImageSharp.fluid}
            alt="Desenho do Luffy"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria2.childImageSharp.fluid}
            alt="Desenho do Naruto, Sasuke e Sakura"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria3.childImageSharp.fluid}
            alt="Desenho com o Boruto e Sarada"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria4.childImageSharp.fluid}
            alt="Desenho divido Naruto e Sasuke"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria5.childImageSharp.fluid}
            alt="Desenho de Fairy Tail"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria6.childImageSharp.fluid}
            alt="Desenho do Tokyo Ghoul"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria7.childImageSharp.fluid}
            alt="Fusão de Foku com o Luffy"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria8.childImageSharp.fluid}
            alt="Desenho do Ban, Sete pecados Capitais"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria9.childImageSharp.fluid}
            alt="Desenho do Tokyo Ghoul"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria10.childImageSharp.fluid}
            alt="Desenho da Vi League of Legends"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria11.childImageSharp.fluid}
            alt="Desenho do Code Gears"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria12.childImageSharp.fluid}
            alt="Desenho de Anime"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria13.childImageSharp.fluid}
            alt="Desenho do Kirito"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria14.childImageSharp.fluid}
            alt="Desenho da Daenerys"
          />
          <S.GeneralImagePequena
            shadow
            fluid={galeria15.childImageSharp.fluid}
            alt="Desenho do Death Note"
          />
        </S.GeneralGaleria>
      </SRLWrapper>
      <DicaExtra />
    </Layout>
  )
}

export default GalleryPage
